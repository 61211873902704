<template>
  <audio ref="audio">
    <source src="./assets/ding.wav" type="audio/ogg" />
  </audio>
  <div v-if="!device_connected" style="padding: 5%;"></div>
  <div v-if="device_connected" style="padding: 0.5%;"></div>

  <div class="index">
    <el-icon :size="device_connected ? 90 : 120">
      <Setting />
    </el-icon>
    <h1>经络仪在线客户端</h1>

    <div v-if="!device_connected" style="padding: 50px;"></div>
    <div v-if="!device_connected">
      <el-button @click="SelectDevice" size="large" :icon="ArrowRightBold" type="primary" plain round>选择设备 / Select
        device</el-button>
    </div>
    <div v-if="device_connected" style="padding-left: 15%;padding-right: 15%;">
      <el-card>
        <div v-if="total_process == -1">
          <el-result icon="success" title="检测结束" sub-title="请使用UHS小程序或APP扫描下方二维码查看报告">
            <template #extra>
              <img :src="'https://api.qrtool.cn/?text=' + Base64.encode(test_data.join(',')).replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D')" width="150" height="150"/><br/>
              <a :href="'http://9756ur59qd31.vicp.fun/get_report?data=' + Base64.encode(test_data.join(',')).replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D').replace('=','%3D')" target="top">{{ Base64.encode(test_data.join(',')) }}</a><el-text></el-text>
              <br/><br/>
              <el-button type="primary" @click="total_process = 0">返回主页</el-button>
            </template>
          </el-result>
        </div>
        <div v-if="total_process == 0">
          <p>脉诊仪数据</p>
          <h1>{{ device_value }}</h1>
          <el-button @click="total_process = 1" size="large" :icon="ArrowRightBold" type="primary" plain round>
            开始检测
          </el-button>
        </div>
        <div v-if="total_process > 0">
          <el-row>
            <el-col :span="12">
              <img alt="Vue logo" :src="'./img/main' + total_process + '.bmp'">
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <p>当前进度</p>
                  <el-progress v-if="present_status == 2" type="circle" :percentage="present_process / 10"
                    status="exception" />
                  <el-progress v-if="present_status == 1" type="circle" :percentage="present_process / 10"
                    status="success" />
                  <el-progress v-if="present_status == 0" type="circle" :percentage="present_process / 10"
                    :indeterminate="true" />
                </el-col>
                <el-col :span="12">
                  <p>整体进度</p>
                  <el-progress type="circle" :percentage="(total_process / 0.24).toFixed(2)" />
                </el-col>
              </el-row>
              <p>脉诊仪数据</p>
              <h1>{{ device_value }}</h1>
              <el-button type="primary" @click="total_process = 0">重新检测</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div style="padding: 50px;"></div>
  </div>
</template>
<style>
.index {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<script setup lang="ts">
import { Setting, ArrowRightBold } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import {Base64} from 'js-base64'
const audio = ref();
const webhid_device = ref();
const device_connected = ref(false);
const total_process = ref(0);
const present_process = ref(0);
const present_status = ref(0);
const delay_counter = ref(0);
// 0: process 1: finish 2: error
const test_data = ref([0]);

const device_value = ref(0);
navigator.hid.ondisconnect = (event: any) => {
  if (device_connected.value && event.device.opened) {
    device_connected.value = false;
    ElNotification({
      title: '设备断开连接',
      message: 'Device Disconnected',
      type: 'warning',
    })
  }
};

const SelectDevice = async () => {
  const devices = await navigator.hid.requestDevice({
    filters: [{
      vendorId: 0x3677,
    }]
  });

  webhid_device.value = devices[0];
  if (webhid_device.value) {
    if (!webhid_device.value.opened) await webhid_device.value.open();
    if (webhid_device.value.opened) {
      ElNotification({
        title: '设备已连接',
        message: 'Device Connected',
        type: 'success',
      })
      device_connected.value = true;

      webhid_device.value.oninputreport = (event: any) => {
        if (delay_counter.value <= 2) {
          delay_counter.value++;
          //return;
        }
        delay_counter.value = 0;
        var data = new Uint8Array(event.data.buffer);
        device_value.value = data[1];
        if (total_process.value > 0) {
          if (device_value.value == 0) {
            if (present_status.value == 1) {
              //new
              total_process.value++;
              present_process.value = 0;
              present_status.value = 0;
              return;
            }
            if (present_status.value == 0 && present_process.value != 0) {
              present_status.value = 2;
              return;
            }
          } else {
            if (present_status.value == 2) {
              present_status.value = 0;
              present_process.value = 0;
              return;
            }
            if (present_status.value == 0) {
              if (present_process.value <= 1000) {
                present_process.value += 10;
              } else {
                present_status.value = 1;
                test_data.value[total_process.value] = device_value.value;
                audio.value.play();
                if (total_process.value >= 24){
                  console.log(test_data.value);
                  total_process.value = -1;
                }
              }
            }
          }
        }
      };
    }
  }
}
</script>